
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {useCaptains} from "../../hooks/captains/captaininfo";

const CaptainList = () => {
    const { captains } = useCaptains();

    return (
        <>
            <div className="table-responsive-xl">
                <table className="table mt-0">
                    <thead>
                    <tr>
                        <th scope="col">Capitan</th>
                        <th scope="col">Ships</th>
                        <th scope="col">Territories</th>
                    </tr>
                    </thead>
                    <tbody>
                    {captains?.map((captain, index) =>
                        <tr key={index}>
                            <th scope="row">
                                <div className="community-post-wrap">
                                    <div className="community-post-content">
                                        <a href="/#">{captain}</a>
                                    </div>
                                </div>
                            </th>
                            <td>0</td>
                            <td>0</td>
                            <td className="community-bell"><a href="/#"><i className="far fa-bell"/></a></td>
                        </tr>
                    )}
                    {captains?.length === 0 && <tr>
                        <td colSpan={4}>You do not yet have any captains!</td>
                    </tr>}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default CaptainList;