import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
    const { logout, isAuthenticated } = useAuth0();

    return (
        isAuthenticated && <li><a href="/#" onClick={() => logout()}><i className=""/>sign out</a></li>
            );
};

export default LoginButton;