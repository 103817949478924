import React, {useEffect, useState} from 'react'
import {useFactions} from "../../hooks/factions/factioninfo";
import Faction from "./Faction";
import EditFaction from "./EditFaction";
import FactionList from "./FactionList";

function FactionsArea() {
    const {factions, fetchFactions} = useFactions();
    const [currentFaction, setCurrentFaction] = useState(null);

    useEffect(() => {
        if(!currentFaction && factions.length > 0) setCurrentFaction(factions[0]);
    }, [factions]);

    useEffect(() => {}, [currentFaction]);

    function handleSelectFaction(faction) {
        console.log("Changing faction to " + faction.name);
        setCurrentFaction(faction);
    }

    return (
        <div className="community-area primary-bg pt-120 pb-175">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="community-wrap">
                            {currentFaction && <Faction faction={currentFaction} onFactionUpdated={faction => {
                                setCurrentFaction(faction);
                                fetchFactions();
                            }} />}
                            <Faction onFactionUpdated={(faction) => {
                                setCurrentFaction(faction);
                                fetchFactions();
                            }} />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <aside className="community-sidebar">
                            <FactionList factions={factions} onFactionSelected={faction => handleSelectFaction(faction)} />
                        </aside>
                    </div>
                </div>
            </div>
            <div className="community-bg-shape"><img src="assets/img/images/community_bg_shape.png" alt="" /></div>
        </div>
    )
}

export default FactionsArea