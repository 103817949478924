import React from 'react';
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import GameSingleArea from "../overview/GameSingleArea";
import HeaderStarpath from "../../components/header/HeaderStarpath";
import Breadcrumb from "../../components/header/breadcrumb";
import FooterStarpath from "../../components/footer/FooterStarpath";
import CommunityArea from "../community/CommunityArea";
import UserProfileArea from "./UserProfileArea";

function UserProfile() {
    return (
        <>
            <HeaderStarpath/>
            <main>
                <Breadcrumb title={"Account Settings"} breadcrumbs={[{name: "Account", "link": "/profile"}]}/>

                <UserProfileArea/>

            </main>
            <FooterStarpath/>
        </>
    )
}

export default UserProfile