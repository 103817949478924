import React, {useEffect, useState} from "react";
import {RingLoader} from "react-spinners";
import {useAuth0} from "@auth0/auth0-react";

const FullscreenLoader = () => {
    const { isLoading} = useAuth0();
    const loaderStyle = {
        position: 'fixed',   // Fixed position
        zIndex: '9999',      // High z-index value
        width: '100%',
        height: '100%',
        animation: 'spin 2s linear infinite',
        backgroundColor: '#000',  // Set solid black background
        top: '50%',          // Center the loader vertically
        left: '50%',         // Center the loader horizontally
        transform: 'translate(-50%, -50%)',  // Adjust for the loader's own dimensions
        // center content
        display: 'flex', // Added for centering
        justifyContent: 'center', // Center horizontally
        alignItems: 'center',  // Center vertically

        transition: 'opacity 0.5s',  // Transition effect
        opacity: '0',  // Default is hidden
        backgroundImage: 'url(/assets/img/backgrounds/login.jpg)',
        // Use background cover to fit the image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };
    const [isVisible, setVisible] = useState(true);
    const [divVisibility, setDivVisibility] = useState(true);

    useEffect(() => {
        if(isLoading) setDivVisibility(true);
        setVisible(isLoading);
        const timer = setInterval(() => {
            setDivVisibility(isLoading);
        }, 500);

        return () => clearInterval(timer);
    }, [isLoading]);

    return <>
        {divVisibility && <div style={{...loaderStyle, opacity: isVisible ? '1' : '0', pointerEvents: isVisible ? 'auto' : 'none'}}>
            <RingLoader color={"#2c84f3"} loading={isLoading} size={150}/>
        </div>}
    </>
}

export default FullscreenLoader;