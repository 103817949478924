import React, {useEffect, useState} from 'react';

import {apiGet} from "../api";

export const useBlogCategories = (limit) => {
    const [blogCategories, setBlogCategories] = useState([]);
    const [blogCategoriesError, setBlogCategoriesError] = useState(false);

    useEffect(() => {
        const fetchBlogCategories = async () => {
            const entries = await apiGet("blog/categories", `limit=${limit}`);

            if (!entries.error) setBlogCategories(entries);
            else setBlogCategoriesError(entries.error);
        };

        fetchBlogCategories();
    }, []);

    return {blogCategories, blogCategoriesError};
}