import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    return (
        !isAuthenticated && <li><a href="/#" onClick={() => loginWithRedirect()}><i className="far fa-edit"/>sign in</a></li>
            );
};

export default LoginButton;