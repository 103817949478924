import React,{ useEffect } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import ScrollToTop from "react-scroll-to-top";
import ProfileHeader from "../Authentication/ProfileHeader";


function HeaderStarpath() {

 
  useEffect(()=>{
    //SubMenu Dropdown Toggle
      if ($('.menu-area li.menu-item-has-children ul').length) {
        $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

      }

      //Mobile Nav Hide Show
      if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.menu-area .push-menu').html();
        $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

        //Dropdown Button
        $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        });


        $('.menu-backdrop, .mobile-menu .close-btn').click (()=>{
          $('body').removeClass('mobile-menu-visible');
        })


        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
          $('body').addClass('mobile-menu-visible');
        });
      }

  },[]);

  useEffect(()=>{
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $('.scroll-to-target').removeClass('open');
    
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $('.scroll-to-target').addClass('open');
      }
    });
    
  },[])
  
  return (

	<header className="third-header-bg home-six-header">
     <ScrollToTop smooth top="500" color="#000" />
        <div className="bg" />
        <div className="container custom-container">
          <div className="header-top-area t-header-top-area d-none d-lg-block">
            <div className="row">
              <div className="col-sm-6">
                <div className="header-top-social">
                  <ul>
                    <li>Follow</li>
                    <li><a href="https://www.youtube.com/channel/UCa59KNYlqyNdy-Tcgm4k9Gg"><i
                        className="fab fa-youtube"/></a></li>
                    <li><a href="https://www.twitch.tv/yolanother"><i className="fab fa-twitch"/></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6">
                <ProfileHeader/>
              </div>
            </div>
          </div>
        </div>
      <div id="sticky-header" className='menu-area'>
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler"><i className="fas fa-bars"/></div>
              <div className="main-menu menu-style-two">
                  <nav>
                    <div className="logo d-block d-lg-none">
                      <a href="/"><img width={"32"} src="/assets/img/logo/logo.png" alt="Logo" /></a>
                    </div>
                    <div className="navbar-wrap d-none d-lg-flex">
                      <ul className="left">
                        <li><a href="/">Home</a></li>
                        <li><a href="/galaxymap">Galaxy Map</a></li>
                        <li><a href="/shipyards">Ship Yards</a></li>
                        <li><a href="/shipcombat">Combat</a></li>
                      </ul>
                      <div className="logo">
                        <a href="/"><img src="/assets/img/logo/starpath_header.png" alt="Logo"/></a>
                      </div>
                      <ul className="right">
                        <li><a href="/#">Profile</a>
                          <ul className="submenu">
                            <li><a href="/account">Account</a></li>
                            <li><a href="/profile/captains">Captains</a></li>
                            <li><a href="/profile/factions">Factions</a></li>
                            <li><a href="/profile/manufacturer">Manufacturer Profile</a></li>
                          </ul>
                        </li>
                        <li><a href="/#">Blog</a>
                          <ul className="submenu">
                            <li><a href="/blog">News</a></li>
                            <li><a href="/blog-details">Starpath Blog</a></li>
                          </ul>
                        </li>
                        <li><a href="/#">About</a>
                          <ul className="submenu">
                            <li><a href="/about-us">About Us</a></li>
                            <li><a href="/privacy">Privacy Policy</a></li>
                          </ul>
                        </li>
                        {/*
                        <li><a href="/about-us">About</a></li>
                        <li><a href="/#">Pages</a>
                          <ul className="submenu">
                            <li><a href="/upcoming-games">Upcoming Games</a></li>
                            <li><a href="/game-single">Game Single</a></li>
                          </ul>
                        </li>
                        <li><a href="/game-overview">Overview</a></li>
                        <li><a href="/community">Community</a></li>
                        <li><a href="/shop">Store</a></li>
                        <li><a href="/#">Blog</a>
                          <ul className="submenu">
                            <li><a href="/blog">News Page</a></li>
                            <li><a href="/blog-details">News Details</a></li>
                          </ul>
                        </li>
                        <li><a href="/contact">contact</a></li>*/}
                      </ul>
                    </div>
                    <div className="header-action">
                      {/*<ul>
                        <li className="header-search"><a href="/#" data-toggle="modal" data-target="#search-modal"><i
                            className="fas fa-search"/></a></li>
                      </ul>*/}
                    </div>
                  </nav>
                </div>
               {/* mobile navbar */}
            <div className="mobile-menu-wrap main-menu d-block d-lg-none">
                    <nav>
                      <div  className="navbar-wrap push-menu">
                        <ul className="navigation">
                          <li><a href="/#">Home</a></li>
                          <li><a href="/galaxymap">Galaxy Map</a></li>
                          <li><a href="/shipyards">Ship Yards</a></li>
                          <li><a href="/shipcombat">Combat</a></li>
                          <li className=" menu-item-has-children"><Link to="/#">Profile</Link>
                            <ul className="submenu">
                              <li><a href="/account">Account</a></li>
                              <li><a href="/profile/captains">Captains</a></li>
                              <li><a href="/profile/factions">Factions</a></li>
                              <li><a href="/profile/manufacturer">Manufacturer Profile</a></li>
                            </ul>
                          </li>
                          <li><Link to="/shop">Store</Link></li>
                          <li className=" menu-item-has-children"><Link to="/blogs">Blog</Link>
                            <ul className="submenu">
                              <li><a href="/blog">News</a></li>
                              <li><a href="/blog-details">Starpath Blog</a></li>
                            </ul>
                          </li>
                          <li className=" menu-item-has-children"><Link to="/#">About</Link>
                            <ul className="submenu">
                              <li><a href="/about-us">About Us</a></li>
                              <li><a href="/privacy">Privacy Policy</a></li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </nav>
            </div>
              {/* mobile-menu           */}

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn"><i className="fas fa-times"/></div>
                  <div className="nav-logo"><Link to="/"><img src="assets/img/logo/logo.png" alt=""
                                                              title='true'/></Link>
                  </div>
                  <div className="menu-outer">
                  
                    </div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li><a href="/#"><span className="fab fa-twitter" /></a></li>
                        <li><a href="/#"><span className="fab fa-facebook-square" /></a></li>
                        <li><a href="/#"><span className="fab fa-pinterest-p" /></a></li>
                        <li><a href="/#"><span className="fab fa-instagram" /></a></li>
                        <li><a href="/#"><span className="fab fa-youtube" /></a></li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
                </div>
              {/* Modal Search */}
              <div className="modal fade" id="search-modal" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <form>
                      <input type="text" placeholder="Search here..." />
                      <button><i className="fa fa-search" /></button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom-bg" />
      </header>
  )
}

export default HeaderStarpath