import React from 'react'

function TeamMem() {
  return (
	 <section className="team-member-area  pt-115 pb-125">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three text-center mb-70">
                <h2>Our team <span>members</span></h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="team-member-box text-center mb-50">
                <div className="team-member-thumb">
                  <img src="https://avatars.githubusercontent.com/u/645359?s=400&u=ed0c9015b6117b7ca0ee484c05af8879e55cbb77&v=4" alt="" />
                  <div className="team-member-social">
                    <ul>
                      <li><a href="https://github.com/yolanother"><i className="fab fa-github"/></a></li>
                      <li><a href="https://www.youtube.com/channel/UCa59KNYlqyNdy-Tcgm4k9Gg"><i className="fab fa-youtube"/></a></li>
                      <li><a href="https://www.twitch.tv/yolanother"><i className="fab fa-twitch"/></a></li>
                      <li><a href="https://www.linkedin.com/in/aaronkjackson"><i className="fab fa-linkedin-in"/></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-content">
                  <h4><a href="/#">Yolan</a></h4>
                  <span>Lead Developer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default TeamMem