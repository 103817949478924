import React, {useEffect} from 'react';
import {MoonLoader} from "react-spinners";

function RedirectPage({url}) {
    // Redirect on load to url
    useEffect(() => {
        if(url) window.location.replace(url);
    }, [url]);

    return (
        <MoonLoader size={150} color={"#123abc"} loading={true}/>
    );
}

export default RedirectPage;