import React, {useEffect, useState} from 'react'
import ManufacturerProfile from "./ManufacturerProfile";
import {useManufacturer} from "../../hooks/manufacturerers/manufacturerinfo";
import ShipList from "./ShipList";

function ManufacturerArea() {
    const {manufacturer, fetchManufacturer, updateManufacturer} = useManufacturer();

    return (
        <div className="community-area primary-bg pt-120 pb-175">
            <div className="container">
                <ManufacturerProfile manufacturer={manufacturer} onManufacturerUpdated={(m) => {
                    //updateManufacturer(m);
                    //fetchManufacturer();
                }} />
            </div>

            <div className="container">
                <ShipList />
            </div>
        </div>
    )
}

export default ManufacturerArea