import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
import RegisterButton from "./RegisterButton";
import LogoutButton from "./LogoutButton";
import ProfileButton from "./ProfileButton";

const Header = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <div className="header-top-login">
            {isAuthenticated ?
                <ul>
                    <ProfileButton/>
                    <LogoutButton/>
                </ul> :
                <ul>
                    <RegisterButton/>
                    <li className="or">or</li>
                    <LoginButton/>
                </ul>
            }
        </div>
        );
    };

export default Header;