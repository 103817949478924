import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {useEmblemImageGeneration} from "../../hooks/factions/emblemcreator";
import {useFactionGeneration} from "../../hooks/factions/factiongenerator";
import {toast} from "react-toastify";
import {useFactions} from "../../hooks/factions/factioninfo";
import {ImageWithCanvasOverlay} from "./ImageWithCanvasOverlay";

const Faction = ({faction= undefined, onFactionUpdated=(faction)=>{}}) => {
    const defaultColor = "#ffffff";

    const [generating, setGenerating] = useState(false);

    const [editedFaction, setEditedFaction] = useState(faction);
    const [factionName, setFactionName] = useState(editedFaction?.name ?? "");
    const [factionColor, setFactionColor] = useState(editedFaction?.color ?? defaultColor);
    const [factionDescription, setFactionDescription] = useState(editedFaction?.description ?? "");
    const [factionEmblem, setFactionEmblem] = useState(editedFaction?.emblem);
    const [factionActive, setFactionActive] = useState(editedFaction?.active ?? false);

    const {generateEmblemImage, imageError} = useEmblemImageGeneration();
    const {generateFaction, generateFactionError} = useFactionGeneration();
    const {createFaction, updateFaction} = useFactions();

    useEffect(() => {
        setFactionName(faction?.name ?? "");
        setFactionColor(faction?.color ?? defaultColor);
        setFactionDescription(faction?.description ?? "");
        setFactionEmblem(faction?.emblem);
        setEditedFaction(faction);
    }, [faction]);

    useEffect(() => {
        if(imageError) toast.error(imageError);
    }, [imageError]);

    useEffect(() => {
        if(generateFactionError) toast.error(generateFactionError);
    }, [generateFactionError]);

    async function generate() {
        setGenerating(true);
        // If description is empty
        if(!factionDescription || factionDescription.length === 0 || factionColor === '#ffffff') {
            const faction = await generateFaction(factionName);
            if(faction) {
                // If color is still default
                if(factionColor === '#ffffff') {
                    setFactionColor(faction.color);
                }

                // If description is still empty
                if(!factionDescription || factionDescription.length === 0) {
                    setFactionDescription(faction.description);
                }
            }
        }

        await generateEmblem();
    }

    async function generateEmblem() {
        setGenerating(true);
        const name = editedFaction?.name ?? factionName;
        if(!name || name.length === 0) return;

        const emblem = await generateEmblemImage(name, factionDescription);
        if(emblem) {
            setFactionEmblem(emblem);
        }
        setGenerating(false);
    }

    async function save() {
        // Create a toast to update and save it to update when complete
        const toastHandle = toast("Saving...", {autoClose: false});

        let faction = {};
        if(editedFaction) {
            faction = await updateFaction(factionName, factionColor, factionDescription, factionEmblem, factionActive);
        } else {
            faction = await createFaction(factionName, factionColor, factionDescription, factionEmblem, factionActive);
        }
        if(faction.error) {
            toast.update(toastHandle, {render: "Error!\n" + faction.error, type: toast.TYPE.ERROR, autoClose: 3000});
        } else {
            if(!editedFaction) {
                // Clear all of the values
                setFactionName("");
                setFactionColor(defaultColor);
                setFactionDescription("");
                setFactionEmblem(undefined);
            }
            toast.update(toastHandle, {render: "Saved!", type: toast.TYPE.SUCCESS, autoClose: 3000});
            onFactionUpdated(faction);
        }
    }

    return (
        <>
            <div className="create-topic mb-25">
                <span>{editedFaction ? editedFaction?.name : "Create Faction"}</span>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <Form.Label className="mb-0">Emblem Preview
                                    </Form.Label>

                                </div>
                                <div className="emblem-preview image-container" style={{
                                    width: '300px',
                                    minHeight: '300px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '0.25rem',
                                    background: '#e9ecef',
                                    backgroundImage: `url(${factionEmblem})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    position: 'relative'
                                }}>

                                    {factionColor != defaultColor ? <ImageWithCanvasOverlay
                                        imageUrl={factionEmblem}
                                        factionColor={factionColor}
                                        style={{width: '300px', height: '300px'}}
                                    /> : <img src={factionEmblem} style={{width: '300px', height: '300px'}}/>}

                                    {generating && <Spinner animation="border" role="status" style={{
                                        position: 'absolute', // Absolutely positioned
                                        top: '50%', // 50% from the top
                                        left: '50%', // 50% from the left
                                        transform: 'translate(-50%, -50%)' // Translated back by 50% of its own size
                                    }}>
                                        <span className="visually-hidden">Generating...</span>
                                    </Spinner>}

                                    {!generating && (editedFaction || factionName?.length > 0) && <div style={{
                                        position: 'absolute',
                                        width: "32px",
                                        height: "32px",
                                        border: 0,
                                        bottom: 0,
                                        right: 0
                                    }} onClick={() => generateEmblem()}>
                                        <i className="fas fa-rocket"></i>
                                    </div>}
                                </div>
                                {/* checkbox indicating if this is the active faction or not */}
                                <Form.Check type="checkbox" label="Current Faction" checked={factionActive} onChange={ev => setFactionActive(ev.target.checked)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                    <Form.Label>Faction Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter faction name"
                                                        value={editedFaction?.name ?? factionName} onChange={ev => setFactionName(ev.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Color</Form.Label>
                                        <Form.Control type="color" style={{backgroundColor: factionColor, height: 64}} value={factionColor} defaultValue={factionColor} title="Choose your color" onChange={ev => setFactionColor(ev.target.value)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3">
                                <Form.Label>Faction Description</Form.Label>
                                <Form.Control as="textarea" rows={5}
                                                value={factionDescription} onChange={ev => setFactionDescription(ev.target.value)}
                                />
                            </Form.Group>
                            <div className="d-flex justify-content-end">
                                {!generating && factionName?.length > 0 && <Button variant="primary" onClick={() => generate()}>
                                    Generate
                                </Button>}

                                <Button className={'ml-2'} variant="primary" onClick={() => save()}>
                                    {editedFaction ? "Save" : "Create"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default Faction;