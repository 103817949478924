import React from 'react';
import HeaderStarpath from "../../components/header/HeaderStarpath";
import Breadcrumb from "../../components/header/breadcrumb";
import FooterStarpath from "../../components/footer/FooterStarpath";
import FactionsArea from "./FactionsArea";

function FactionsPage() {
    return (
        <>
            <HeaderStarpath/>
            <main>
                <Breadcrumb title={"Your Factions"} breadcrumbs={[{name: "Factions", "link": "/profile/factions"}]}/>
                <FactionsArea />
            </main>
            <FooterStarpath/>
        </>
    )
}

export default FactionsPage