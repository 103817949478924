import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {toast} from "react-toastify";
import {useEmblemImageGeneration} from "../../hooks/factions/emblemcreator";
import {useManufacturer} from "../../hooks/manufacturerers/manufacturerinfo";
import {ImageWithCanvasOverlay} from "../factions/ImageWithCanvasOverlay";
import {useFactionGeneration} from "../../hooks/factions/factiongenerator";
import {useBrandingImageGeneration} from "../../hooks/manufacturerers/logocreator";

const ManufacturerProfile = ({manufacturer= undefined, onManufacturerUpdated=(manufacturer)=>{}}) => {
    const defaultColor = "#ffffff";

    const [generating, setGenerating] = useState(false);

    const [editedManufacturer, setEditedManufacturer] = useState(manufacturer);
    const [manufacturerName, setManufacturerName] = useState(editedManufacturer?.name ?? "");
    const [manufacturerColor, setManufacturerColor] = useState(editedManufacturer?.color ?? defaultColor);
    const [manufacturerDescription, setManufacturerDescription] = useState(editedManufacturer?.description ?? "");
    const [manufacturerEmblem, setManufacturerEmblem] = useState(editedManufacturer?.branding);
    const [generatedEmblem, setGeneratedEmblem] = useState(undefined);

    const {generateBrandingImage, imageError} = useBrandingImageGeneration();
    const {updateManufacturer, pastBranding, fetchPastBranding} = useManufacturer();
    const [brandingIndex, setPastBrandingIndex] = useState(0);

    useEffect(() => {
        setManufacturerName(manufacturer?.name ?? "");
        setManufacturerColor(manufacturer?.color ?? defaultColor);
        setManufacturerDescription(manufacturer?.description ?? "");
        setManufacturerEmblem(manufacturer?.branding);
        setEditedManufacturer(manufacturer);
    }, [manufacturer]);

    useEffect(() => {
        // Get the index of manufacturer.branding in pastBranding
        if(!pastBranding || !manufacturer) return;
        const index = pastBranding.findIndex((branding) => branding === manufacturerEmblem);
        setPastBrandingIndex(index);
    }, [pastBranding]);

    useEffect(() => {
        if(imageError) toast.error(imageError);
    }, [imageError]);

    useEffect(() => {
        let index = brandingIndex;
        console.log("brandingIndex", brandingIndex);
        console.log("pastBranding", pastBranding);
        if(brandingIndex >= pastBranding.length) {
            if(generatedEmblem) {
                setManufacturerEmblem(generatedEmblem);
                return;
            }
            else index = pastBranding.length - 1;
        }
        if(pastBranding.length === 0) return;
        setManufacturerEmblem(pastBranding[index]);
    }, [brandingIndex]);

    async function generateEmblem() {
        setGenerating(true);
        const name = editedManufacturer?.name ?? manufacturerName;
        if(!name || name.length === 0) return;

        const branding = await generateBrandingImage(manufacturerDescription);
        if(branding) {
            setPastBrandingIndex(pastBranding.length);
            setGeneratedEmblem(branding);
            setManufacturerEmblem(branding);
        }
        setGenerating(false);
    }

    async function save() {
        // Create a toast to update and save it to update when complete
        const toastHandle = toast("Saving...", {autoClose: false});

        let manufacturer = await updateManufacturer(manufacturerName, manufacturerColor, manufacturerDescription, manufacturerEmblem);
        if(manufacturer.error) {
            toast.update(toastHandle, {render: "Error!\n" + manufacturer.error, type: toast.TYPE.ERROR, autoClose: 3000});
        } else {
            toast.update(toastHandle, {render: "Saved!", type: toast.TYPE.SUCCESS, autoClose: 3000});
            onManufacturerUpdated(manufacturer);
        }
        fetchPastBranding();
    }

    return (
        <>
            <div className="create-topic mb-25">
                <span>{editedManufacturer ? editedManufacturer?.name : "Create Manufacturer"}</span>
                <Form>
                    <Row>
                        <Col style={{width: 300}}>
                            <Form.Group>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <Form.Label className="mb-0">Branding Preview</Form.Label>

                                </div>
                                <div className="branding-preview image-container" style={{
                                    width: '300px',
                                    minHeight: '300px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '0.25rem',
                                    background: '#e9ecef',
                                    backgroundImage: `url(${manufacturerEmblem})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    position: 'relative'
                                }}>

                                    <img src={manufacturerEmblem} style={{width: '300px', height: '300px'}}/>

                                    {generating && <Spinner animation="border" role="status" style={{
                                        position: 'absolute', // Absolutely positioned
                                        top: '50%', // 50% from the top
                                        left: '50%', // 50% from the left
                                        transform: 'translate(-50%, -50%)' // Translated back by 50% of its own size
                                    }}>
                                        <span className="visually-hidden">Generating...</span>
                                    </Spinner>}

                                    {!generating && (editedManufacturer || manufacturerName?.length > 0) && <div style={{
                                        position: 'absolute',
                                        width: "32px",
                                        height: "32px",
                                        border: 0,
                                        bottom: 0,
                                        right: 0
                                    }} onClick={() => generateEmblem()}>
                                        <i className="fas fa-rocket"></i>
                                    </div>}
                                </div>
                                {/* add arroows if there are more than one branding */}
                                {pastBranding && pastBranding.length > 1 && <div className="d-flex justify-content-between align-items-center mt-2" style={{width: 300}}>
                                    <Button variant="outline-secondary" disabled={brandingIndex === 0} onClick={() => {
                                        setPastBrandingIndex(brandingIndex - 1);
                                    }}>
                                        <i className="fas fa-arrow-left"></i>
                                    </Button>
                                    <Button variant="outline-secondary" disabled={brandingIndex === pastBranding.length} onClick={() => {
                                        setPastBrandingIndex(brandingIndex + 1);
                                    }}>
                                        <i className="fas fa-arrow-right"></i>
                                    </Button>
                                </div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Manufacturer Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter manufacturer name"
                                                      value={editedManufacturer?.name ?? manufacturerName} onChange={ev => setManufacturerName(ev.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Color</Form.Label>
                                        <Form.Control type="color" style={{backgroundColor: manufacturerColor, height: 64}}
                                                      value={manufacturerColor} defaultValue={manufacturerColor}
                                                      title="Choose your color" onChange={ev => setManufacturerColor(ev.target.value)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3">
                                <Form.Label>Manufacturer Branding Description</Form.Label>
                                <Form.Control as="textarea" rows={5}
                                              placeholder="Describe how you would like your branding logo to look"
                                              value={manufacturerDescription} onChange={ev => setManufacturerDescription(ev.target.value)}
                                />
                            </Form.Group>
                            <div className="d-flex justify-content-end">
                                {!generating && manufacturerName?.length > 0 && <Button variant="primary" onClick={() => generateEmblem()}>
                                    Generate
                                </Button>}

                                <Button className={'ml-2'} variant="primary" onClick={() => save()}>
                                    {editedManufacturer ? "Save" : "Create"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default ManufacturerProfile;