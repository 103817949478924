import React, {useEffect, useState} from 'react';

import {apiGetAuth, useEndpoint} from "../api";
import {useAuth0} from "@auth0/auth0-react";

export const useBrandingImageGeneration = () => {
    const [error, setError] = useState(null);
    const [factions, setBrandings] = useState([]);
    const {fetchAuth: createBranding} = useEndpoint("manufacturers/create-logo");

    const generateBrandingImage = async (description) => {
        let branding = await createBranding({description});
        if (branding.error) {
            setError(branding);
            return null;
        }

        // Wait for up to 1 minute for the image to be generated. Starting at 1 second interval.
        // Each iteration where branding.status is still queueud, double the interval.
        let interval = 1000;
        let iteration = 0;
        while (iteration < 60 && branding.status === "queued") {
            await new Promise(r => setTimeout(r, interval));
            interval *= 2;
            iteration++;
            branding = await createBranding({"id": branding.id});
            if (branding.error) {
                setError(branding);
                return null;
            }
        }
        await new Promise(r => setTimeout(r, interval));

        return branding.url;
    };

    return {generateBrandingImage, error};
}