import React from 'react'
import Bounce from 'react-reveal/Bounce';

function SliderThree() {
  return (
      <section className="third-banner-bg">
          <div className="bounceInDown" style={{display: 'flex', justifyContent: 'center'}}>
              <Bounce top>
                  <img src="/assets/img/logo/uw_logo.png" width={"75%"} alt=""/>
              </Bounce>
          </div>
      </section>
  )
}

export default SliderThree