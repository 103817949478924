import React, {useEffect, useState} from 'react';

import {apiGetAuth, useEndpoint} from "../api";
import {useAuth0} from "@auth0/auth0-react";

export const useFactions = () => {
    const [error, setError] = useState(null);
    const [factions, setFactions] = useState([]);
    const {fetchAuth: getFactions} = useEndpoint("factions/list");
    const {postAuth: postCreateFaction} = useEndpoint("factions/create");
    const {postAuth: postUpdateFaction} = useEndpoint("factions/edit");

    const createFaction = async (name, color, description, emblem, factionActive) => {
        let faction = await postCreateFaction({name, color, description, emblem, "faction-active": factionActive});
        if (faction.error) {
            setError(faction);
        }

        return faction;
    };

    const updateFaction = async (name, color, description, emblem, factionActive) => {
        let faction = await postUpdateFaction({name, color, description, emblem, "faction-active": factionActive});
        if (faction.error) {
            setError(faction);
        }

        return faction;
    };

    const fetchFactions = async () => {
        const updatedFactions = await getFactions();
        if (!updatedFactions.error) {
            setFactions(updatedFactions);
            return updatedFactions;
        }

        setError(updatedFactions);
        return updatedFactions;
    };

    useEffect(() => {
        fetchFactions();
    }, []);

    return {factions, error, createFaction, updateFaction, fetchFactions};
}