import React, {useEffect, useState} from 'react';

import {apiGetAuth, useEndpoint} from "../api";
import {useAuth0} from "@auth0/auth0-react";

export const useUserProfile = () => {
    const [email, setEmail] = useState([]);
    const [entitlements, setEntitlements] = useState([]);
    const [userProfile, setUserProfile] = useState({});
    const [profileFetchError, setProfileFetchError] = useState([]);
    const {fetchAuth: getProfile, postBodyAuth: submitProfile} = useEndpoint("profile/user-profile");

    async function updateProfile(profile) {
        await submitProfile(profile);
        setUserProfile(profile);
    }

    const fetchUserProfile = async () => {
        const profile = await getProfile();
        if (!profile.error) {
            setEmail(profile.email);
            setEntitlements(profile.entitlements);
            if(!profile.info.profile) profile.info.profile = {};
            setUserProfile(profile.info);
            return profile.info;
        }
        else setProfileFetchError(profile);
    };

    useEffect(() => {
        fetchUserProfile();
    }, []);

    return {userProfile, profileFetchError, updateProfile, fetchUserProfile, entitlements, email};
}