import React from 'react'
import BlogEntry from "./BlogEntry";

const BlogArea = ({blogEntries=[]}) => {
  return (
      <section className="blog-area pt-115 pb-120">
        <div className="container">
          {blogEntries.map((entry, index) => <BlogEntry key={index} entry={entry}/>)}
        </div>
      </section>
  )
}

export default BlogArea