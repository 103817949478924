import React from 'react'
import BannerArea from './indexsix/BannerArea'
import GamesNewsArea from './indexsix/GamesNewsArea'
import LiveStreamingArea from './indexsix/LiveStreamingArea'
import TrendingnewsArea from './indexsix/TrendingnewsArea'
import WorldNewsArea from './indexsix/WorldNewsArea'
import HeaderStarpath from "../header/HeaderStarpath";
import FooterStarpath from "../footer/FooterStarpath";
import SliderStarpath from "./SliderStarpath";
import GameGallery from "./indexthree/GameGallery";
import BlogArea from "../blog/BlogArea";
import {useBlogEntries} from "../../hooks/blog/blogentryhook";


const IndexStarpath = () => {
    /*const blogEntries = [{
        date: "November 27th 2023",
        imgSrc: "https://cdn.discordapp.com/attachments/868382441679769670/1181865391476187166/image.png?ex=65829ceb&is=657027eb&hm=00c94500da40dc61d094f1134f33c7fe57a83c5fd86bd3c451fc885f043ca070&",
        title: "Starpath - Ship Yards",
        text: "\"Star Path - Ship Yards\" is an innovative and immersive entry into the Messy Coder itch jam, where creativity in spaceship design takes center stage. In this engaging game, players step into the role of interstellar engineers, tasked with designing and constructing unique starships that reflect both functionality and imagination. The game capitalizes on the theme of the jam, utilizing the exclusive DotTeam asset pack, and allowing players to explore the vast possibilities of spaceship architecture.\n" +
            "\n" +
            "               The core feature of \"Star Path - Ship Yards\" is its intuitive and robust blueprint tool. This tool enables players to select from a diverse range of components provided by various manufacturers (mirroring asset store publishers), allowing for an unprecedented level of customization and detail in ship design. Players can experiment with different hull shapes, propulsion systems, weaponry, and aesthetic elements to create ships that are not only visually stunning but also serve specific roles in the vastness of space.\n" +
            "\n" +
            "               As players progress, they unlock more complex and advanced components, challenging them to continually evolve their design skills. The game encourages experimentation and learning, as each component comes with its own set of strengths, weaknesses, and unique characteristics.\n" +
            "\n" +
            "               \"Star Path - Ship Yards\" stands out with its impressive graphics and attention to detail. The ships players create are not just static models but can be tested in a simulated space environment, giving immediate feedback on how their designs perform in various interstellar scenarios. This feature adds a layer of realism and practicality to the game, bridging the gap between fantasy and functional design.\n" +
            "\n" +
            "               The game's social aspect is also a highlight, with a community platform where players can share their designs, get feedback, and even collaborate on larger projects. This fosters a sense of camaraderie and collective creativity within the game's community.\n" +
            "\n" +
            "               Overall, \"Star Path - Ship Yards\" is a testament to the power of imagination and engineering, offering a unique and engaging experience for both seasoned designers and newcomers to the world of spaceship construction. With its blend of creative freedom, realistic simulation, and community interaction, it is a standout entry in the Messy Coder itch jam, sure to captivate and inspire players who embark on this star-building adventure."}];
    */
    // Use the blog entries hook
    const {blogEntries} = useBlogEntries(3);

  return (
   <>
     <HeaderStarpath/>
       <main>
           <SliderStarpath/>
           <section style={{height: 750, background: "url(../img/bg/game_gallery_bg.png)"}}>
               <GameGallery/>
           </section>
           <BlogArea blogEntries={blogEntries}/>
                           {/*<BannerArea/>
         <TrendingnewsArea/>
         <WorldNewsArea/>
         <LiveStreamingArea/>
         <GamesNewsArea/>*/}
       </main>
       <FooterStarpath/>
   </>
)
}

export default IndexStarpath