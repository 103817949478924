import React, {useEffect, useState} from 'react';

import {apiGetAuth, useEndpoint} from "../api";
import {useAuth0} from "@auth0/auth0-react";

export const useEmblemImageGeneration = () => {
    const [error, setError] = useState(null);
    const [factions, setEmblems] = useState([]);
    const {fetchAuth: createEmblem} = useEndpoint("factions/create-emblem");

    const generateEmblemImage = async (name, description) => {
        let emblem = await createEmblem({name, description});
        if (emblem.error) {
            setError(emblem);
            return null;
        }

        // Wait for up to 1 minute for the image to be generated. Starting at 1 second interval.
        // Each iteration where emblem.status is still queueud, double the interval.
        let interval = 1000;
        let iteration = 0;
        while (iteration < 60 && emblem.status === "queued") {
            await new Promise(r => setTimeout(r, interval));
            interval *= 2;
            iteration++;
            emblem = await createEmblem({"id": emblem.id});
            if (emblem.error) {
                setError(emblem);
                return null;
            }
        }
        await new Promise(r => setTimeout(r, interval));

        return emblem.url;
    };

    return {generateEmblemImage, error};
}