
import React from 'react';
import {useShips} from "../../hooks/manufacturerers/ships";

const ShipList = () => {
    const { ships } = useShips();

    console.log("Ships:", ships);

    return (
        <>
            <div className="create-topic mb-25">
            <div className="table-responsive-xl">
                <table className="table mt-0">
                    <thead>
                    <tr>
                        <th scope="col" width={128}></th>
                        <th scope="col">Ship Name</th>
                        <th scope="col">Ship Class</th>
                        <th scope="col">Ship Cost</th>
                        <th scope="col">Ship Markup</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ships?.map((ship, index) =>
                        <tr key={index}>
                            <th scope="row">
                                <img src={"https://webapi.starpath.online/ships/thumbnail?id=" + ship.id} width={128}
                                     height={128}/>
                            </th>
                            <td>
                                <div className="community-post-content">
                                    {ship.name}
                                </div>
                            </td>
                            <td>
                                <div className="community-post-content">
                                    {ship.shipClass}
                                </div>
                            </td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                    )}
                    {ships?.length === 0 && <tr>
                        <td colSpan={4}>You haven't built any ships yet.</td>
                    </tr>}
                    </tbody>
                </table>
            </div>
            </div>
        </>
    );
};

export default ShipList;