import React, {useCallback, useEffect, useState} from 'react'
import HeaderStarpath from "../../components/header/HeaderStarpath";
import FooterStarpath from "../../components/footer/FooterStarpath";
import StarpathGL from "./StarpathGL";

const StarpathShipyards = () => {
    return (
        <>
            <HeaderStarpath/>
            <StarpathGL title={"Ship Yards"} experience={"shipyards"} binaryName={"shipyards"} />
            <FooterStarpath/>
        </>
    )
}

export default StarpathShipyards