import React, {useEffect, useState} from 'react';

import {apiGetAuth, useEndpoint} from "../api";
import {useAuth0} from "@auth0/auth0-react";

export const useFriends = () => {
    const [error, setError] = useState(null);
    const [friends, setFriends] = useState([]);
    const {fetchAuth: getFriends} = useEndpoint("friends/list");

    const fetchFriends = async () => {
        const updatedFriends = await getFriends();
        if (!updatedFriends.error) {
            setFriends(updatedFriends);
            return updatedFriends;
        }

        setError(updatedFriends);
        return updatedFriends;
    };

    useEffect(() => {
        fetchFriends();
    }, []);

    return {friends, error};
}