import React, {useEffect, useState} from 'react'

import { BrowserRouter as Router, Switch} from 'react-router-dom';
import HomeTwo from './pages/homes/HomeTwo';
import HomeOne from './pages/homes/HomeOne';
import HomeThree from './pages/homes/HomeThree';
import Shop from './pages/store/Shop';
import Contact from './pages/contact/Contact';
import Community from './pages/community/Community';
import Overview from './pages/overview/Overview';
import AboutUs from './pages/aboutpage/aboutuspage/AboutUs';
import Upcoming from './pages/aboutpage/upcoming/Upcoming';
import GameSingle from './pages/aboutpage/singlegame/GameSingle';
import Blogs from './pages/blogs/Blogs';
import BlogDetails from './pages/blogdetails/BlogDetails'
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import HomeFour from './pages/homes/HomeFour';
import HomeFive from './pages/homes/HomeFive';
import HomeSix from './pages/homes/HomeSix';
import HomeSeven from './pages/homes/HomeSeven';
import StarpathHome from "./pages/homes/StarpathHome";
import StarpathGalaxymap from "./pages/webgl/galaxymap";
import StarpathStaryards from "./pages/webgl/staryards";
import UserProfile from "./pages/profile/userprofile";
import BlogPage from "./pages/blogs/BlogPage";
import FactionsPage from "./pages/factions/FactionsPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth0} from "@auth0/auth0-react";
import {AuthenticationGuard} from "./components/Authentication/AuthenticationGuard";
import FullscreenLoader from "./components/Authentication/FullscreenLoader";
import {CallbackPage} from "./pages/Auth/CallbackPage";
import RedirectPage from "./components/Authentication/RedirectPage";
import StarpathShipcombat from "./pages/webgl/shipcombat";
import StarpathShipViewer from "./pages/webgl/shipviewer";
import ManufacturerProfile from "./pages/manufacturers/ManufacturerProfilePage";
import ManufacturerProfilePage from "./pages/manufacturers/ManufacturerProfilePage";



// Include this style tag in the head of your HTML file
// <style>
// @keyframes spin {
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// }
// </style>


function App() {

   return (

       <div className="App">

          <Router>

             <Switch>
                <ScrollToTopRoute exact={true} path='/device'>
                   <RedirectPage url={"https://" + process.env.REACT_APP_AUTH0_DOMAIN + "/activate"}/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/callback'>
                   <CallbackPage/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/galaxymap'>
                   <StarpathGalaxymap/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/shipyards'>
                   <StarpathStaryards/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/shipcombat'>
                   <StarpathShipcombat/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/shipviewer'>
                   <StarpathShipViewer/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/index-2'>
                   <HomeOne/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/index-3'>
                   <HomeThree/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/shop'>
                   <Shop/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/contact'>
                   <Contact/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/community'>
                   <Community/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/overview'>
                   <Overview/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/account'>
                   <AuthenticationGuard component={UserProfile}/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/profile/factions'>
                   <AuthenticationGuard component={FactionsPage}/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/profile/manufacturer'>
                   <AuthenticationGuard component={ManufacturerProfilePage}/>
                </ScrollToTopRoute>

                <ScrollToTopRoute exact={true} path='/about-us'>
                   <AboutUs/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/upcoming-games'>
                   <Upcoming/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/game-single'>
                   <GameSingle/>
                </ScrollToTopRoute>

                <ScrollToTopRoute exact={true} path='/blogs-sidebar-sampl'>
                   <Blogs/>
                </ScrollToTopRoute>

                <ScrollToTopRoute exact={true} path='/blogs/:id'>
                   <BlogPage/>
                </ScrollToTopRoute>

                <ScrollToTopRoute exact={true} path='/blogs'>
                   <BlogDetails/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/blog-details'>
                   <BlogDetails/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/blog-details/:id'>
                   <BlogDetails/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/index-4'>
                   <HomeFour/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/index-5'>
                   <HomeFive/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/index-6'>
                   <HomeSix/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path='/index-7'>
                   <HomeSeven/>
                </ScrollToTopRoute>

                <ScrollToTopRoute exact={true} path='*'>
                   <StarpathHome/>
                </ScrollToTopRoute>
             </Switch>
          </Router>
          <ToastContainer/>

          <FullscreenLoader />
       </div>
   );
}

export default App;
