import React, {useEffect} from 'react';
import ReactDOM, {createRoot} from 'react-dom';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";

function AppWithCallbackAfterRender() {
    useEffect(() => {
        console.log('rendered');
    });

    return <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={`${window.location.origin}`}
        logoutRedirectUri={`${window.location.origin}`}
        audience={'https://webapi.starpath.online'}
        authorizationParams={{
            audience: 'https://webapi.starpath.online',
        }}
    >
        <App />
    </Auth0Provider>
}

const container = document.getElementById('app');
const root = createRoot(document.getElementById('root'));
root.render(<AppWithCallbackAfterRender />);

