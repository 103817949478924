import React, {useEffect, useState} from 'react';

import {apiGetAuth, useEndpoint} from "../api";

export const useShips = () => {
    const [error, setError] = useState(null);
    const [ships, setShips] = useState([]);
    const {fetchAuth: getShips} = useEndpoint("ships/list");

    const fetchShips = async () => {
        const updatedShips = await getShips();
        if (!updatedShips.error) {
            setShips(updatedShips.ships);
            return updatedShips.ships;
        }

        console.log("Ships:", updatedShips);

        setError(updatedShips);
        return updatedShips;
    };

    useEffect(() => {
        fetchShips();
    }, []);

    return {ships, error};
}