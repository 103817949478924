import React, {useEffect, useState} from 'react';

import {apiGet} from "../api";

export const useBlogEntries = (limit) => {
    const [blogEntries, setBlogEntries] = useState([]);
    const [blogEntriesIteration, setBlogEntriesIteration] = useState(0);

    useEffect(() => {
        const fetchBlogEntries = async () => {
            const entries = await apiGet("blog/entries", `limit=${limit}`);

            if (!entries.error) setBlogEntries(entries);
            setBlogEntriesIteration(blogEntriesIteration + 1);
        };

        fetchBlogEntries();
    }, []);

    return {blogEntries, setBlogEntriesIteration};
}

export const useBlogEntry = (id) => {
    const [blogEntry, setBlogEntry] = useState({});
    const [blogEntryError, setBlogEntryError] = useState(false);

    useEffect(() => {
        const fetchBlogEntries = async () => {
            const entries = await apiGet("blog/entry", {id});

            if (!entries.error) setBlogEntry(entries);
            else setBlogEntryError(entries.error);
        };

        fetchBlogEntries();
    }, [id]);

    return {blogEntry, blogEntryError};
}