
import React from 'react';
import {useFriends} from "../../hooks/friends/friendsinfo";

const FriendsList = () => {
    const { friends } = useFriends();

    return (
        <>
            <div className="table-responsive-xl">
                <table className="table mt-0">
                    <tbody>
                    <tr className="community-post-type">
                        <th><span>Friends</span></th>
                    </tr>
                    {friends?.map((captain, index) =>
                        <tr key={index}>
                            <th scope="row">
                                <div className="community-post-wrap">
                                    <div className="community-post-content">
                                        <a href="/#">{captain}</a>
                                    </div>
                                </div>
                            </th>
                            <td>0</td>
                            <td>0</td>
                            <td className="community-bell"><a href="/#"><i className="far fa-bell"/></a></td>
                        </tr>
                    )}
                    {friends?.length === 0 && <tr>
                        <td colSpan={4}>You haven't added any friends yet!</td>
                    </tr>}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default FriendsList;