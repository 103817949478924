import React, {useEffect, useRef} from "react";

export function ImageWithCanvasOverlay({imageUrl, factionColor}) {
    const canvasRef = useRef(null);

    useEffect(() => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            // Setting canvas size
            canvas.width = 300;
            canvas.height = 300;

            // Calculating scale to maintain aspect ratio
            const scale = Math.min(canvas.width / image.width, canvas.height / image.height);

            // Calculate the x and y coordinates to center the image
            const x = (canvas.width / 2) - (image.width / 2) * scale;
            const y = (canvas.height / 2) - (image.height / 2) * scale;

            // Draw the image
            ctx.drawImage(image, 0, 0, image.width * scale, image.height * scale);

            // Apply the color overlay
            ctx.fillStyle = factionColor;
            ctx.globalCompositeOperation = 'source-atop';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        };
    }, [imageUrl, factionColor]);

    return (
        <div style={{width: '300px', height: '300px', position: 'relative'}}>
            <canvas ref={canvasRef} style={{width: '100%', height: '100%'}}></canvas>
        </div>
    );
}