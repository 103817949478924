import React, {useEffect} from 'react'
import {useBlogCategories} from "../../hooks/blog/blogcategories";
import {useBlogEntries, useBlogEntry} from "../../hooks/blog/blogentryhook";
import {useParams} from "react-router-dom";
// import blogentry.css
import "./blogentry.css";

function BlogContent() {
  const {id} = useParams();
  const {blogCategories} = useBlogCategories(10);
  const {blogEntries} = useBlogEntries(10);
  const {blogEntry} = useBlogEntry(id);

  useEffect(() => {
    console.log("Blog id: ", id);
    console.log("Blog entry: ", blogEntry);
  }, [blogEntry]);

  return (
	 <section className="blog-area primary-bg pt-120 pb-175">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-list-post blog-details-wrap">
                {blogEntry && blogEntry.content && <div className="blog-list-post-content">
                  <img width={"100%"} src={"https://webapi.starpath.online/blog/media?id=" + blogEntry.featured_media}
                       alt=""/>
                  <div className="blog-list-post-tag mb-25 mt-4">
                    {blogEntry?.categories?.map((category, index) =>
                        <a key={index}>{category.name}</a>
                    )}
                  </div>
                  <h1 style={{color: "black"}}>
                    <div dangerouslySetInnerHTML={{__html: blogEntry.title.rendered}}/>
                  </h1>
                  <div className="blog-list-post-meta">
                    <i className="far fa-calendar-alt"/>{blogEntry.date} - <i
                      className="far fa-user"/>{blogEntry.author.name}
                  </div>

                  <div className={"blog-entry"} dangerouslySetInnerHTML={{__html: blogEntry.content.rendered}}/>
                </div>}
              </div>

            </div>
            <div className="col-lg-4">
              <aside className="blog-sidebar">
                <div className="widget mb-45">
                  <div className="sidebar-about">
                    <div className="sidebar-about-thumb">
                      <img
                          src="https://avatars.githubusercontent.com/u/645359?s=400&u=ed0c9015b6117b7ca0ee484c05af8879e55cbb77&v=4" alt="" />
                    </div>
                    <div className="sidebar-widget-title mb-15">
                      <h4>ABOUT THE <span>AUTHOR</span></h4>
                    </div>
                    <div className="sidebar-about-content">
                      <p>Yolan is the primary developer building Starpath Online.</p>
                    </div>
                  </div>
                </div>
                <div className="widget mb-45">
                  <div className="sidebar-search">
                    <form >
                      <input type="text" placeholder="Type and hit enter..." />
                      <button><i className="fas fa-search" /></button>
                    </form>
                  </div>
                </div>
                <div className="widget mb-45">
                  <div className="sidebar-widget-title mb-25">
                    <h4>category <span>post</span></h4>
                  </div>
                  <div className="sidebar-cat">
                    <ul>
                      {blogCategories?.map((category, index) => <li>
                        <a href={"/blog-details/"}>{category.name} ( {category.count} )</a>
                      </li>)}
                    </ul>
                  </div>
                </div>
                <div className="widget mb-45">
                  <div className="sidebar-widget-title mb-25">
                    <h4>Recent <span>post</span></h4>
                  </div>
                  <div className="rc-post-list">
                    <ul>
                      {blogEntries?.map((entry, index) =>
                        <li key={index}>
                          <div className="rc-post-thumb">
                            <a href={"/blog-details/" + entry.id}><img width={100}
                                                                       src={"https://webapi.starpath.online/blog/media?size=medium&id=" + entry.featured_media}
                                                                       alt=""/></a>
                          </div>
                          <div className="rc-post-content">
                          <h5><a href={"/blog-details/" + entry.id}><div dangerouslySetInnerHTML={{__html: entry.title.rendered}}/></a></h5>
                            <span>{entry.timestamp}</span>
                          </div>
                      </li>
                      )}
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
  )
}

export default BlogContent