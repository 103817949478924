import React from 'react';

function FactionList({factions, onFactionSelected=(faction) => {}}) {
    return (
        <div className="widget community-widget">
            <div className="community-widget-title mb-25">
                <h5>factions</h5>
            </div>
            <div className="community-sidebar-game">
                <ul>
                    {factions?.map((faction, index) => <li key={index}>
                        <div style={{width: 64, height: 64, marginRight: 16}}>
                            {faction.emblem && <div className="sidebar-new-game-thumb">
                                <div className={"div-link"} onClick={() => onFactionSelected(faction)}>
                                    <img height={64} src={faction.emblem} alt={faction.name}/>
                                </div>
                        </div>}
                        </div>
                        <div className="sidebar-new-game-content">
                            <h5><div className={"div-link"} onClick={() => onFactionSelected(faction)}>{faction.name}</div></h5>
                        </div>
                    </li>)}
                </ul>
            </div>
        </div>
    );
}

export default FactionList;