import React, {useCallback, useEffect, useState} from 'react'
import {Unity, useUnityContext} from "react-unity-webgl";
import {useToken} from "../../hooks/api";

const StarpathGL = ({experience, title, binaryName="WebGL"}) => {
    const {getToken} = useToken();
    const [unityReady, setUnityReady] = useState(undefined);

    const {unityProvider, sendMessage, addEventListener, removeEventListener} = useUnityContext({
        loaderUrl: `https://starpath.online/webgl/${experience}/Build/${binaryName}.loader.js`,
        dataUrl: `https://starpath.online/webgl/${experience}/Build/${binaryName}.data.gz`,
        frameworkUrl: `https://starpath.online/webgl/${experience}/Build/${binaryName}.framework.js.gz`,
        codeUrl: `https://starpath.online/webgl/${experience}/Build/${binaryName}.wasm.gz`,
        streamingAssetsUrl: `https://starpath.online/webgl/${experience}/StreamingAssets`,
        companyName: "Doubling Technologies",
        productName: `Starpath - ${title}`,
        showBanner: false,
    });
    const [windowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const initUnity = useCallback((webglComponent) => {
        setUnityReady(webglComponent);
    }, []);

    useEffect(() => {
        if(!unityReady) return;
        async function send() {
            console.log("Received WebGLInit event from " + unityReady);
            const token = await getToken();
            try {
                sendMessage(unityReady, "SetToken", token);
            } catch (e) {
                console.error(e);
            }
        }

        send();
    }, [unityReady])

    useEffect(() => {
        addEventListener("WebGLInit", initUnity);

        return () => {
            removeEventListener("WebGLInit", initUnity);
        }
    }, [addEventListener, removeEventListener, initUnity]);

    return (
        <>
            <Unity unityProvider={unityProvider} style={{
                width: windowSize.width-25,
                height: windowSize.height-200,
                marginTop: "-15px",
                marginBottom: "-15px",
            }} />
        </>
    )
}

export default StarpathGL