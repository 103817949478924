import React, {useEffect, useState} from 'react'
import {useUserProfile} from "../../hooks/profile/userprofile";
import {AvatarCreator, AvatarCreatorConfig} from "@readyplayerme/react-avatar-creator";
import CaptainList from "./CaptainList";
import FriendsList from "./FriendsList";
import {Avatar} from "@readyplayerme/visage";

function UserProfileArea() {
    const [hasAvatar, setHasAvatar] = useState(false);
    const {userProfile, updateProfile, fetchUserProfile, entitlements} = useUserProfile();
    const [avatarUrl, setAvatarUrl] = useState('');
    const config = {
        clearCache: true,
        bodyType: 'fullbody',
        quickStart: false,
        language: 'en',
    };

    const style = { width: '100%', height: '80vh', border: 'none' };
    console.log("Profile: ", userProfile, userProfile?.profile?.avatar?.thumbnail);
    const handleOnAvatarExported = async (event) => {
        var profile = await fetchUserProfile();
        profile.profile.avatar = {
            "thumbnail": event.data.url.replace("glb", "png"),
            "model": event.data.url
        };
        await updateProfile(profile);
    };

    useEffect(() => {
        setHasAvatar(userProfile?.profile?.avatar?.thumbnail !== undefined);
    }, [userProfile]);

    return (
        <div className="community-area primary-bg pt-120 pb-175">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="community-wrap">
                            <div className="table-responsive-xl">
                                {!hasAvatar && <AvatarCreator subdomain="starpath" config={config} style={style}
                                                   onAvatarExported={handleOnAvatarExported}/>}
                                {hasAvatar && <Avatar style={{height: "50vh"}}
                                                      cameraZoomTarget={{x: -.11, y: 0, z: 1}}
                                                      animationSrc={"https://readyplayerme.github.io/visage/male-idle.glb"}
                                                      modelSrc={userProfile?.profile?.avatar?.model} />}
                            </div>
                            <CaptainList />
                            <FriendsList />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <aside className="community-sidebar">
                            <div className="widget community-widget mb-30">
                                <div className="community-widget-title mb-25">
                                    <h5>Account Profile</h5>
                                </div>
                                <div className="community-sidebar-social">
                                    <img src={userProfile?.avatar} width={"100%"}/>

                                    <div className={"mt-2"}>
                                        Display Name: <span style={{color: "#182d54"}}>{userProfile?.displayname}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="widget community-widget">
                                <div className="community-widget-title mb-25">
                                <h5>entitlements</h5>
                                </div>
                                <div className="community-sidebar-game">
                                    <ul>
                                        <li>
                                            <div className="sidebar-new-game-content">
                                                {entitlements?.map((entitlement, index) => <h5 key={index}>{entitlement}</h5>)}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
            <div className="community-bg-shape"><img src="assets/img/images/community_bg_shape.png" alt="" /></div>
        </div>
    )
}

export default UserProfileArea