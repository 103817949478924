import React, {useState} from 'react'
import HeaderStarpath from "../../components/header/HeaderStarpath";
import FooterStarpath from "../../components/footer/FooterStarpath";
import {Unity, useUnityContext} from "react-unity-webgl";

const StarpathGalaxymap = () => {
    const {unityProvider} = useUnityContext({
        loaderUrl: "https://starpath.online/webgl/Starmap/Dist.loader.js",
        dataUrl: "https://starpath.online/webgl/Starmap/Dist.data.gz",
        frameworkUrl: "https://starpath.online/webgl/Starmap/Dist.framework.js.gz",
        codeUrl: "https://starpath.online/webgl/Starmap/Dist.wasm.gz",
    });
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });


    return (
        <>
            <HeaderStarpath/>
                <Unity unityProvider={unityProvider} style={{
                    width: windowSize.width-25,
                    height: windowSize.height-200,
                    marginTop: "-15px",
                    marginBottom: "-15px",
                }} />
            <FooterStarpath/>
        </>
    )
}

export default StarpathGalaxymap