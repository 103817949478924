import React, {useEffect, useState} from 'react';

import {apiGet} from "./api";

export const useGalleryEntries = (limit) => {
    const [galleryEntries, setGalleryEntries] = useState([]);
    const [galleryEntriesIteration, setGalleryEntriesIteration] = useState(0);

    useEffect(() => {
        const fetchGalleryEntries = async () => {
            const entries = await apiGet("gallery/entries", `limit=${limit}`);

            if (!entries.error) setGalleryEntries(entries);
            setGalleryEntriesIteration(galleryEntriesIteration + 1);
        };

        fetchGalleryEntries();
    }, []);

    return [galleryEntries, setGalleryEntriesIteration];
}