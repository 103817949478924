import React from 'react'
import Breadcrumb from './Breadcrumb'
import HeaderStarpath from "../../components/header/HeaderStarpath";
import FooterStarpath from "../../components/footer/FooterStarpath";
import FullPageBlogEntry from "../../components/blog/fullpageblogentry";
import {useParams} from "react-router-dom";
import {useBlogEntry} from "../../hooks/blog/blogentryhook";


function BlogPage() {
	const {id} = useParams();
	const {blogEntry} = useBlogEntry(id);

	return (
		<>
			<HeaderStarpath/>
			<main>
				<Breadcrumb title={"Blog"} breadcrumbs={[{name: "Blog", "link": "/blogs"}]}/>
				<FullPageBlogEntry blogEntry={blogEntry}/>
			</main>
			<FooterStarpath/>
		</>
	)
}

export default BlogPage