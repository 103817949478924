import React, {forwardRef, useImperativeHandle, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";

function getCookieVariable(variableName) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(variableName + '=')) {
            return decodeURIComponent(cookie.substring(variableName.length + 1));
        }
    }
    return null;
}

function setCookieVariable(variableName, variableValue, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    const cookieValue = encodeURIComponent(variableValue) + '; expires=' + expirationDate.toUTCString() + '; path=/';
    document.cookie = variableName + '=' + cookieValue;
}

export function createUri(endpoint, session, params) {
    let paramString = "";
    // Iterate over the params and add them to the uri
    for (const [key, value] of Object.entries(params)) {
        if(value === undefined) continue;
        paramString += `&${key}=${encodeURIComponent(value)}`;
    }
    return `https://webapi.starpath.online/${endpoint}?session=${session}${paramString}`;
}

export async function startSession() {
    let session = getCookieVariable("webapi.starpath.online::session");

    if(!session) {
        const response = await fetch("https://webapi.starpath.online/auth/start-session");
        const body = await response.json();
        window.sposession = body.session;
        setCookieVariable("webapi.starpath.online::session", body.session, 7);
    }
    window.sposession = session;
    return session;
}

async function handleResult(result) {
    try {
        const text = await result.text();
        // try to parse the json safely
        try {
            return JSON.parse(text);
        } catch (e) {
            return {"status": result.status, "error": result.statusText, data: text}
        }
    } catch (e) {
        console.error(e, result);
        return {"error": e.toString()}
    }
}

export async function apiGet(endpoint, params={}) {
    try {
        const session = await startSession();
        const uri = createUri(endpoint, session, params);
        const result = await fetch(uri,
            {
                mode: 'cors',
                method: 'GET',
                cache: "no-cache"
            });
        return await handleResult(result);
    } catch (e) {
        console.error(e);
        return {"error": e.toString()}
    }
}

async function apiGetAuth(token, endpoint, params={}) {
    const session = await startSession();
    const uri = createUri(endpoint, session, {token: token, ...params});
    try {
        const headers = {
            mode: 'cors',
            method: "GET",
            cache: "no-cache",
            Authorization: `Bearer ${token}`
        };
        const result = await fetch(uri, headers);
        return await handleResult(result);
    } catch (e) {
        console.error(e);
        return {"error": e.toString()}
    }
}

async function apiPostAsync(endpoint, body, params={}) {
    try {
        const session = await startSession();
        const uri = createUri(endpoint, session, params);

        // If the body is an object convert it to a json string
        if(typeof body === "object") {
            body = JSON.stringify(body);
        }

        const result = await fetch(uri,
            {
                mode: 'cors',
                method: "POST",
                cache: "no-cache",
                body: body
            });
        return await handleResult(result);
    } catch (e) {
        console.error(e)
        return {"error": e.toString()}
    }
}

async function apiPostAuthAsync(token, endpoint, body, params={}) {
    try {
        const session = await startSession();
        const uri = createUri(endpoint, session, params);

        // If the body is an object convert it to a json string
        if(typeof body === "object") {
            body = JSON.stringify(body);
        }

        const result = await fetch(uri,
            {
                mode: 'cors',
                method: "POST",
                cache: "no-cache",
                body: body,
                Authorization: `Bearer ${token}`
            });
        return await handleResult(result);
    } catch (e) {
        console.error(e)
        return {"error": e.toString()}
    }
}

export const useToken = () => {
    const { getAccessTokenSilently, user } = useAuth0();

    async function getToken() {
        return await getAccessTokenSilently({
            authorizationParams: {
                audience: "https://webapi.starpath.online",
                scopes: ['openid', 'profile', 'email']
            }
        });
    }

    return {getToken, user};
}

export const useEndpoint = (endpoint) => {
    const { getAccessTokenSilently, user } = useAuth0();

    async function fetch({params={}}) {
        return await apiGet(endpoint, params);
    }

    async function fetchAuth(params={}) {
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: "https://webapi.starpath.online",
                scopes: ['openid', 'profile', 'email']
            }
        });
        return await apiGetAuth(token, endpoint, params);
    }

    async function post(body, params={}) {
        return await apiPostAsync(endpoint, undefined, params);
    }

    async function postAuth(params={}) {
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: "https://webapi.starpath.online",
                scopes: ['openid', 'profile', 'email']
            }
        });
        return await apiPostAuthAsync(token, endpoint, undefined, params);
    }

    async function postBody(body, params={}) {
        return await apiPostAsync(endpoint, body, params);
    }

    async function postBodyAuth(body, params={}) {
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: "https://webapi.starpath.online",
                scopes: ['openid', 'profile', 'email']
            }
        });
        return await apiPostAuthAsync(token, endpoint, body, params);
    }

    return {fetchAuth, fetch, user, postBody, postBodyAuth, post, postAuth};
}
