import React, {useEffect, useState} from 'react';

import {apiGetAuth, useEndpoint} from "../api";

export const useCaptains = () => {
    const [error, setError] = useState(null);
    const [captains, setCaptains] = useState([]);
    const {fetchAuth: getCaptains} = useEndpoint("captains/list");

    const fetchCaptains = async () => {
        const updatedCaptains = await getCaptains();
        if (!updatedCaptains.error) {
            setCaptains(updatedCaptains);
            return updatedCaptains;
        }

        setError(updatedCaptains);
        return updatedCaptains;
    };

    useEffect(() => {
        fetchCaptains();
    }, []);

    return {captains, error};
}