import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ProfileButton = () => {
    const { user, isAuthenticated } = useAuth0();

    return isAuthenticated ? (
        <>
        <a href="/account">
            <i className="far fa-user" />&nbsp;{user.name}&nbsp;
        </a>
            &nbsp;
        </>
    ) : null;
};

export default ProfileButton;