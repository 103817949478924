import React from 'react'
import {Link} from 'react-router-dom'

function Breadcrumb({breadcrumbs = [{name: "Home", link: "/"}], title}) {
    // If the first link in breadcrumbs isn't "Home", insert it
    if (breadcrumbs[0].name !== "Home") breadcrumbs.unshift({name: "Home", link: "/"});

    // Split title into two parts with the second part being the last word in a span
    // This allows us to style the last word differently
    const titleParts = title.split(" ");
    const lastWord = titleParts.pop();
    const firstPart = titleParts.join(" ");

    return (
        <section className="breadcrumb-area breadcrumb-bg s-breadcrumb-bg">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-content text-center">
                            <h2>{firstPart} <span>{lastWord}</span></h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {breadcrumbs.map((breadcrumb, index) =>
                                        <>
                                        {index < breadcrumbs.length - 1 ?
                                            <li key={index} className="breadcrumb-item">
                                                <Link to={breadcrumb.link}>{breadcrumb.name}</Link>
                                            </li> :
                                            <li key={index} className="breadcrumb-item active" aria-current="page">{breadcrumb.name}</li>
                                        }
                                        </>
                                    )}
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Breadcrumb