import React, {useEffect, useState} from 'react';

import {apiGetAuth, useEndpoint} from "../api";
import {useAuth0} from "@auth0/auth0-react";

export const useManufacturer = () => {
    const [error, setError] = useState(null);
    const [manufacturer, setManufacturer] = useState([]);
    const [pastBranding, setPastBranding] = useState([]);
    const {fetchAuth: getManufacturer} = useEndpoint("manufacturers/info");
    const {postAuth: postUpdateManufacturer} = useEndpoint("manufacturers/edit");
    const {fetchAuth: getPastBranding} = useEndpoint("manufacturers/past-branding");

    useEffect(() => {
        fetchManufacturer();
        fetchPastBranding();
    }, []);

    const updateManufacturer = async (name, color, description, emblem, factionActive) => {
        let faction = await postUpdateManufacturer({name, color, description, emblem, "faction-active": factionActive});
        if (faction.error) {
            setError(faction);
        }

        return faction;
    };

    const fetchPastBranding = async () => {
        const updatedPastBranding = await getPastBranding();
        if (!updatedPastBranding.error) {
            setPastBranding(updatedPastBranding);
            return updatedPastBranding;
        }

        setError(updatedPastBranding);
        return updatedPastBranding;
    }

    const fetchManufacturer = async () => {
        const updatedManufacturer = await getManufacturer();
        if (!updatedManufacturer.error) {
            setManufacturer(updatedManufacturer);
            return updatedManufacturer;
        }

        setError(updatedManufacturer);
        return updatedManufacturer;
    };

    useEffect(() => {
        fetchManufacturer();
    }, []);

    return {manufacturer, pastBranding, error, updateManufacturer, fetchManufacturer, fetchPastBranding};
}