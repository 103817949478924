import React from 'react'
import Breadcrumb from "../../components/header/breadcrumb";
import BlogContent from './BlogContent'
import HeaderStarpath from "../../components/header/HeaderStarpath";
import FooterStarpath from "../../components/footer/FooterStarpath";

function BlogDetails() {
  return (
	<>
		<HeaderStarpath/>
		<main>
			<Breadcrumb title={"Starpath Blog"} breadcrumbs={[{name: "Starpath Blog", "link": "/blog-details"}]}/>
			<BlogContent/>
		</main>
		<FooterStarpath/>
	</>
  )
}

export default BlogDetails