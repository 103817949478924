import React, {useCallback, useEffect, useState} from 'react'
import HeaderStarpath from "../../components/header/HeaderStarpath";
import FooterStarpath from "../../components/footer/FooterStarpath";
import StarpathGL from "./StarpathGL";

const StarpathShipcombat = () => {

    return (
        <>
            <HeaderStarpath/>
            <StarpathGL title={"Combat"} experience={"shipcombat"} binaryName={"shipcombat"} />
            <FooterStarpath/>
        </>
    )
}

export default StarpathShipcombat