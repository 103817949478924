import React, {useCallback, useEffect, useState} from 'react'
import HeaderStarpath from "../../components/header/HeaderStarpath";
import FooterStarpath from "../../components/footer/FooterStarpath";
import StarpathGL from "./StarpathGL";

const StarpathShipViewer = () => {

    return (
        <>
            <HeaderStarpath/>
            <StarpathGL title={"Ship Viewer"} experience={"shipviewer"} binaryName={"shipviewer"} />
            <FooterStarpath/>
        </>
    )
}

export default StarpathShipViewer