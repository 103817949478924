import React from 'react';
import HeaderTwo from '../../../components/header/HeaderTwo';
import Footer from '../../../components/footer/Footer';
import Breadcrumb from './Breadcrumb';
import AboutInner from './AboutInner'
import LatestMatch from './LatestMatch'
import TeamMem from './TeamMem'
import HeaderStarpath from "../../../components/header/HeaderStarpath";
import FooterStarpath from "../../../components/footer/FooterStarpath";

function AboutUs() {
	return (
		<>
			<HeaderStarpath/>
			<main>
				<Breadcrumb/>
				{/*<AboutInner/>
	<LatestMatch/>*/}
				<TeamMem/>

			</main>
			<FooterStarpath/>
		</>
	)
}

export default AboutUs
