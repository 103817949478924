import React from 'react';

const BlogEntry = ({ entry }) => {
    console.log(entry);
    const { date, commentCount, preview_image: imgSrc, title, body_summary: text } = entry;
    const link = `/blog-details/${entry.id}`;

    return (
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
                <div className="video-blog-item mb-50">
                    <div className="video-blog-thumb">
                        <a href={link}>
                            <img width={"100%"}
                                 src={"https://webapi.starpath.online/blog/media?size=medium&id=" + entry.featured_media}
                                 alt=""/>
                        </a>
                    </div>
                    <div className="video-blog-content">
                        <div className="video-blog-meta">
                            <ul>
                                <li><i className="far fa-clock" /> {entry.date}</li>
                                {commentCount && <li>
                                    <a href="/react/public#">
                                        <i className="far fa-comments" /> {commentCount} Comment
                                    </a>
                                </li>}
                            </ul>
                        </div>
                        <h4 className="title">
                            <a href={link}><div dangerouslySetInnerHTML={{__html: title.rendered}}/> </a>
                        </h4>
                        <h5 style={{
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden'
                        }}>
                            <div dangerouslySetInnerHTML={{__html: entry.excerpt.rendered}}/>
                        </h5>
                        <a href={link} className="read-more">
                            Read More <i className="fas fa-caret-right" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogEntry;