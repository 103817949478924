import React from 'react';
import HeaderStarpath from "../../components/header/HeaderStarpath";
import Breadcrumb from "../../components/header/breadcrumb";
import FooterStarpath from "../../components/footer/FooterStarpath";
import ManufacturerArea from "./ManufacturerArea";

function ManufacturerProfilePage() {
    return (
        <>
            <HeaderStarpath/>
            <main>
                <Breadcrumb title={"Your Manufacturer Profile"} breadcrumbs={[{name: "Manufacturer Profile", "link": "/profile/manufacturer"}]}/>
                <ManufacturerArea />
            </main>
            <FooterStarpath/>
        </>
    )
}

export default ManufacturerProfilePage