import React from 'react'

const FooterStarpath = () => {
  return (
    <footer className="home-six-footer">
        <div className="footer-top footer-bg">
          {/* newsletter-area */}
          <div className="newsletter-area s-newsletter-area" style={{width: '512px', marginLeft: 'auto', marginRight:'auto', justifyContent: 'center'}}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="newsletter-wrap"  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '16px', marginTop: '-32px'}}>
                    {/*<div className="section-title newsletter-title">
                      <h2>Our <span>Newsletter</span></h2>
                    </div>
                    <div className="newsletter-form">
                      <form action="#">
                        <div className="newsletter-form-grp">
                          <i className="far fa-envelope" />
                          <input type="email" placeholder="Enter your email..." />
                        </div>
                        <button>SUBSCRIBE <i className="fas fa-paper-plane" /></button>
                      </form>
                    </div>*/}<a href="/"><img src="/assets/img/logo/w_logo.png" alt=""/></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* newsletter-area-end */}
          <div className="container">
            <div className="row justify-content-between">
              {/*<div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="footer-logo mb-35">
                    <a href="/"><img src="assets/img/logo/w_logo.png" alt="" /></a>
                  </div>
                  <div className="footer-text">
                    <p></p>
                    {/*<div className="footer-contact">
                      <ul>
                        <li><i className="fas fa-map-marker-alt" /> <span>Address : </span>PO Box W75 Street
                          lan West new queens</li>
                        <li><i className="fas fa-headphones" /> <span>Phone : </span>+24 1245 654 235</li>
                        <li><i className="fas fa-envelope-open" /><span>Email : </span>info@exemple.com</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>*/}
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Experiences</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a href="/shipyards">Ship Yards</a></li>
                      <li><a href="/#">Galaxy Map</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Need Help?</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a href="/#">Privacy Policy</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Follow us</h5>
                  </div>
                  <div className="footer-social">
                    <ul>
                      <li><a href="/#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="/#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="/#"><i className="fab fa-pinterest-p" /></a></li>
                      <li><a href="/#"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                </div>{/*
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Newsletter Sign Up</h5>
                  </div>
                  <div className="footer-newsletter">
                    <form action="#">
                      <input type="text" placeholder="Enter your email" />
                      <button><i className="fas fa-rocket" /></button>
                    </form>
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap s-copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="copyright-text">
                  <p>Copyright © 2023 <a href="/#">Doubling Technologies</a> All Rights Reserved.</p>
                </div>
              </div>
              {/*<div className="col-lg-6 col-md-6 d-none d-md-block">
                <div className="payment-method-img text-right">
                  <img src="assets/img/images/card_img.png" alt="img" />
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </footer>
  )
}

export default FooterStarpath