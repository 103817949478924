import React, {useEffect, useState} from 'react';

import {apiGetAuth, useEndpoint} from "../api";
import {useAuth0} from "@auth0/auth0-react";

export const useFactionGeneration = () => {
    const [error, setError] = useState(null);
    const [factions, setFactions] = useState([]);
    const {fetchAuth: createFaction} = useEndpoint("factions/generate-faction-details");

    const generateFaction = async (name) => {
        let faction = await createFaction({name});
        if (faction.error) {
            setError(faction);
            return null;
        }

        return faction;
    };

    return {generateFaction, error};
}